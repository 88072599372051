body {
  font-family: Sora, system-ui, sans-serif;
  background-color: #0e161e; 
  color: #f0f6fc;
}

.container {
  margin: 0 auto;
  max-width: 900px;
  padding: 20px;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  
}

th {
  background-color: #343a40;
  color: white;
  white-space: nowrap;
}

a {
  text-decoration: none;
  color: #5eff0d;
}

a:hover {
  text-decoration: underline;
}

.badge-img {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #343a40;
  color: white;
}

img {
  vertical-align: middle;
}
